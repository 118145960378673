// All rewrites to HTML pages from "marketing" next.config.js should be added here.
// If the link is a rewrite to an HTML page, using next/link will cause a 404 error.
// (This is a problem with the Pages router, but won't be fixed. It is fixed in App router.)
// Instead, we should navigate to the page directly using the browser navigation.
export const rewrites = [
  // Astro pages START
  '/',
  '/customer-stories/3m',
  '/customer-stories/bango',
  '/customer-stories/citi',
  '/customer-stories/expedia',
  '/customer-stories',
  '/customer-stories/jetblue',
  '/customer-stories/kerry',
  '/customer-stories/marriott',
  '/customer-stories/mintable',
  '/customer-stories/mundipharma',
  '/customer-stories/roche',
  '/customer-stories/shopify',
  '/customer-stories/singtel',
  '/customer-stories/ufs',
  '/customer-stories/viewqwest',
  '/customer-stories/vonage',
  '/events/EventForm',
  '/events/b2b-apr-2025',
  '/events/consumer-mar-2025',
  '/events/finance-mar-2025',
  '/events/future-of-marketing',
  '/events/future-proof-consumer-marketing',
  '/events/future-proof-financial-services-marketing',
  '/events/future-proof-healthcare-pharma-marketing',
  '/events/future-proof-marketingops-revops',
  '/events/healthcare-mar-2025',
  '/events',
  '/events/marketing-roi',
  '/events/masterclass-cpg-ai',
  '/events/transforming-customer-experience-ai',
  '/privacy',
  '/terms',
  // Astro pages END

  // '/.wf_auth', // 404
  '/marketing-organizations-survey-report-2021',
  '/why',
  '/tools/glassmorphism-css-generator',
  // '/skills', // Replaced by pages/skills/index.jsx
  // '/enterprise/company', // 404
  // '/clients', // Replaced by pages/clients/index.jsx
  '/events',
  '/events/apac-roundtable-48fd3dd8',
  '/events/digital-leaders-roundtable-2022',
  '/events/marketing-transformation-for-financial-services-roundtable',
  '/case-studies',
  // if (process.env.NODE_ENV === 'production')
  '/enterprise/company/3m',
  '/enterprise/company/generic-account-based-page-redesign-apr22',
  '/enterprise/company/on-demand-teams',
  '/enterprise/company/organon',
  '/enterprise/company/reckitt-on-demand-teams',
  '/enterprise/company/template-company-page',
  '/enterprise/company/unilever-on-demand-teams',
  '/enterprise/company/veeam',
];
